import {
  Autocomplete,
  Checkbox,
  TextField,
  Box,
  FormControlLabel,
  Button,
  createTheme,
  ThemeProvider,
  Grid,
} from '@mui/material';
import React, { useEffect, useState, useCallback, useMemo } from 'react';

const FilterBar = (props) => {
  const [caliFilter, setCaliFilter] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('All');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedCaliber, setSelectedCaliber] = useState('All');

  // Sort brands, categories, and calibers alphanumerically, keeping "All" at the top
  const sortedBrands = useMemo(() => {
    const sorted = [...props.brands]
      .filter((brand) => brand && brand !== 'All') // Remove null/undefined and "All"
      .sort((a, b) => a.localeCompare(b));
    return ['All', ...sorted]; // Add "All" back at the top
  }, [props.brands]);

  const sortedCategories = useMemo(() => {
    const sorted = [...props.categories]
      .filter((category) => category && category !== 'All') // Remove null/undefined and "All"
      .sort((a, b) => a.localeCompare(b));
    return ['All', ...sorted]; // Add "All" back at the top
  }, [props.categories]);

  const sortedCalibers = useMemo(() => {
    const sorted = [...props.calibers]
      .filter((caliber) => caliber && caliber !== 'All') // Remove null/undefined and "All"
      .sort((a, b) => a.localeCompare(b));
    return ['All', ...sorted]; // Add "All" back at the top
  }, [props.calibers]);

  const theme = createTheme({
    palette: {
      primary: {
        main: props.color,
      },
    },
  });

  const callback = useCallback(() => {
    props.filterCallback(caliFilter, selectedBrand, selectedCategory, selectedCaliber);
  }, [caliFilter, selectedBrand, selectedCategory, selectedCaliber, props]);

  useEffect(() => {
    callback();
  }, [caliFilter, selectedBrand, selectedCategory, selectedCaliber, callback]);

  const handleChangeFilter = () => {
    setCaliFilter(!caliFilter);
  };

  const handleChangeBrand = (val) => {
    setSelectedBrand(val);
  };

  const handleChangeCategory = (val) => {
    setSelectedCategory(val);
  };

  const handleChangeCaliber = (val) => {
    setSelectedCaliber(val);
  };

  const handleReset = () => {
    // Reset all filters to default
    setCaliFilter(false);
    setSelectedBrand('All');
    setSelectedCategory('All');
    setSelectedCaliber('All');
  };

  // Trigger the filter callback after the reset
  useEffect(() => {
    if (
      selectedBrand === 'All' &&
      selectedCategory === 'All' &&
      selectedCaliber === 'All' &&
      !caliFilter
    ) {
      callback();
    }
  }, [selectedBrand, selectedCategory, selectedCaliber, caliFilter, callback]);

  return (
    <Box
      sx={{
        width: '100%',
        borderBottom: 'solid',
        borderColor: 'slategray',
        backgroundColor: 'whitesmoke',
        p: 1,
      }}
    >
      <Grid container justifyContent='space-between' alignItems='center' spacing={2}>
        <Grid item xs={12} sm={2} md={2} bgcolor={'whitesmoke'} padding={1}>
          <ThemeProvider theme={theme}>
            <Button
              variant='contained'
              sx={{
                backgroundColor: props.color,
                color: theme.palette.getContrastText(props.color),
                width: '100%',
              }}
              onClick={handleReset}
            >
              Reset Filters
            </Button>
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={2} md={2} bgcolor={'whitesmoke'} padding={1}>
          <FormControlLabel
            sx={{
              fontSize: 'large',
              bgcolor: 'whitesmoke',
              padding: 1,
              width: '100%',
            }}
            control={
              <Checkbox
                size='small'
                checked={caliFilter}
                onChange={handleChangeFilter}
                sx={{
                  color: props.color,
                  '&.Mui-checked': {
                    color: props.color,
                  },
                }}
              />
            }
            label={'California Compliant'}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2} bgcolor={'whitesmoke'} padding={1}>
          <Autocomplete
            value={selectedBrand}
            sx={{ width: '100%' }}
            options={sortedBrands}
            onChange={(event, value) => handleChangeBrand(value)}
            disableClearable={true}
            renderInput={(params) => <TextField {...params} label='Brand' />}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2} bgcolor={'whitesmoke'} padding={1}>
          <Autocomplete
            value={selectedCategory}
            sx={{ width: '100%' }}
            options={sortedCategories}
            onChange={(event, value) => handleChangeCategory(value)}
            disableClearable={true}
            renderInput={(params) => <TextField {...params} label='Category' />}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2} bgcolor={'whitesmoke'} padding={2}>
          <Autocomplete
            value={selectedCaliber}
            sx={{ width: '100%' }}
            options={sortedCalibers}
            onChange={(event, value) => handleChangeCaliber(value)}
            disableClearable={true}
            getOptionLabel={(option) => (option ? option : 'Unknown Caliber')}
            renderInput={(params) => <TextField {...params} label='Caliber/Gauge' />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(FilterBar);
