import axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import ItemCard from './Components/ItemCard';
import FilterBar from './Components/FilterBar';
import TitleBar from './Components/TitleBar';

const OutdoorPrizes = () => {
  const [products, setProducts] = useState([]);
  const [pageData, setPageData] = useState([{ color: '#FFFFFF' }]);
  const [brandList, setBrandList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [caliberList, setCaliberList] = useState([]); // New state for calibers
  const [id, setId] = useState('');
  const [tier, setTier] = useState('');
  const [error, setError] = useState('');
  const [caliFilter, setCaliFilter] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('All');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedCaliber, setSelectedCaliber] = useState('All');

  useEffect(() => {
    const parseUrl = () => {
      const path = window.location.pathname;
      const parts = path.split('-');
      if (parts.length > 1) {
        const idPart = parts[0].substring(1);
        const tierPart = parts[1][0];
        setId(idPart);
        setTier(tierPart);
      }
    };
    parseUrl();
  }, []);

  useEffect(() => {
    if (id && tier) {
      getData();
    }
  }, [id, tier]);

  const getData = async () => {
    try {
      const res = await axios.post(
        '/outdoorprizes',
        {},
        {
          params: {
            id: id,
            tier: tier,
          },
        }
      );

      if (res.data.products.length === 0) {
        setError(
          'Invalid scan. Please try again. If the problem persists, please notify the event coordinator.'
        );
      } else {
        setPageData({
          color: res.data.color,
          title: res.data.title,
          logo: res.data.logo_url,
          results: res.data.results,
        });
        setProducts(res.data.products);

        const brands = ['All', ...new Set(res.data.products.map((bnd) => bnd.brand))];
        const categories = ['All', ...new Set(res.data.products.map((cat) => cat.category))];
        const calibers = ['All', ...new Set(res.data.products.map((prd) => prd.caliber_filter))];

        setBrandList(brands);
        setCategoryList(categories);
        setCaliberList(calibers);
        setError(''); // Reset the error state on successful data fetch
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error');
    }
  };

  // Memoize the filtered products based on the current filter states
  const filteredProducts = useMemo(() => {
    let tempArray = [...products];

    if (caliFilter) {
      tempArray = tempArray.filter((prd) => prd.ca_approved === '1');
    }

    if (selectedBrand !== 'All') {
      tempArray = tempArray.filter((prd) => prd.brand === selectedBrand);
    }

    if (selectedCategory !== 'All') {
      tempArray = tempArray.filter((prd) => prd.category === selectedCategory);
    }

    if (selectedCaliber !== 'All') {
      tempArray = tempArray.filter((prd) => prd.caliber_filter === selectedCaliber);
    }

    return tempArray.length > 0 ? tempArray : ['No Results'];
  }, [products, caliFilter, selectedBrand, selectedCategory, selectedCaliber]);

  const pageBody = () => (
    <Grid container spacing={1} sx={{ backgroundColor: 'whitesmoke' }}>
      <Grid item xs={12}>
        <TitleBar color={pageData.color} title={pageData.title} logo={pageData.logo} />
      </Grid>
      <Grid item xs={12}>
        <FilterBar
          filterCallback={(cc, brnd, ctgry, calbr) => {
            setCaliFilter(cc);
            setSelectedBrand(brnd);
            setSelectedCategory(ctgry);
            setSelectedCaliber(calbr);
          }}
          brands={brandList}
          categories={categoryList}
          calibers={caliberList}
          color={pageData.color}
        />
      </Grid>
      <Grid item container spacing={2} xs={12}>
        {filteredProducts.length === 1 && filteredProducts[0] === 'No Results' ? (
          <Grid item xs={12}>
            <Typography fontSize={25} textAlign='center'>
              No products. Please select different filters or reset them.
            </Typography>
          </Grid>
        ) : (
          filteredProducts.map((prdt) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={prdt.id}>
              <ItemCard item={prdt} color={pageData.color} />
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  );

  return (
    <>
      {products.length !== 0 ? (
        pageBody()
      ) : error ? (
        <Typography>{error}</Typography>
      ) : (
        <Box
          textAlign={'center'}
          paddingTop={20}
          paddingBottom={'100%'}
          sx={{ bgcolor: 'whitesmoke' }}
        >
          <CircularProgress size={60} />
        </Box>
      )}
    </>
  );
};

export default OutdoorPrizes;
