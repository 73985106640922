import './App.css';
import axios from 'axios';
import OutdoorPrizes from './OutdoorPrizes';

function App() {
  if (window.location.host !== 'localhost:3000') {
    axios.defaults.baseURL = 'https://ninja.sportssouth.dev';
  }
  return <OutdoorPrizes />;
}

export default App;
