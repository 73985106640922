import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  createTheme,
} from '@mui/material';

const ItemCard = (props) => {
  const { item, color } = props; // Destructure color from props
  const cc = item.ca_approved === '0' ? 'No' : 'Yes';

  const [open, setOpen] = useState(false); // State to control modal open/close

  const handleOpen = () => {
    window.open(item.highres_image_url, '_blank'); // External image view for highres
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const formatKey = (key) => {
    // Format keys to replace underscores with spaces and capitalize words
    return key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: color, // Use the color prop for theme
      },
    },
  });

  return (
    <>
      <Card
        sx={{
          border: 'solid',
          borderColor: 'slategray',
          borderRadius: 2,
          height: '100%',
          margin: 1,
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} display='flex' justifyContent='center'>
              <CardMedia
                component='img'
                image={item.logo_image_url}
                style={{
                  maxWidth: 'auto',
                  maxHeight: '50px',
                  padding: 2,
                  objectFit: 'contain',
                }}
                alt={item.brand}
              />
            </Grid>
            <Grid item xs={12} sm={8} display='flex' alignItems='center' justifyContent='flex-end'>
              <Typography textAlign={'right'} variant='h6' fontWeight={'bold'} color={'GrayText'}>
                {item.model}
              </Typography>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='center'>
              <CardMedia
                component='img'
                image={item.small_image_url}
                alt={item.description}
                onClick={handleOpen}
                style={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={15} paddingBottom={3} textAlign='center'>
                {item.description}
              </Typography>
            </Grid>
            {[
              { label: 'Supplier #', value: item.product_code },
              { label: 'Manufacturer #', value: item.manufacturer_sku },
              { label: 'UPC #', value: item.upc_code },
              { label: 'California Compliant', value: cc },
              { label: 'Brand', value: item.brand },
              { label: 'Type', value: item.category },
              { label: 'Caliber', value: item.caliber_filter },
            ].map(
              (info, index) =>
                info.value && ( // Only show if the value is not null or empty
                  <Grid item xs={12} key={index} container>
                    <Grid item xs={6}>
                      <Typography
                        fontWeight={'bold'}
                        style={{
                          backgroundColor: info.label === 'Supplier #' ? '#f0f0f0' : 'transparent',
                          padding: info.label === 'Supplier #' ? '4px' : '0',
                          borderRadius: info.label === 'Supplier #' ? '4px' : '0',
                        }}
                      >
                        {info.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign='right'>
                      <Typography
                        style={{
                          backgroundColor: info.label === 'Supplier #' ? '#f0f0f0' : 'transparent',
                          padding: info.label === 'Supplier #' ? '4px' : '0',
                          borderRadius: info.label === 'Supplier #' ? '4px' : '0',
                        }}
                      >
                        {info.value}
                      </Typography>
                    </Grid>
                  </Grid>
                )
            )}
            <Grid item xs={12} display='flex' justifyContent='center'>
              <Button
                variant='contained'
                color='primary'
                onClick={handleDialogOpen}
                sx={{
                  backgroundColor: color, // Use color for button
                  '&:hover': {
                    backgroundColor: color, // Remove hover effect
                  },
                }}
              >
                Full Specs
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Full Specs Modal */}
      <Dialog open={open} onClose={handleDialogClose} maxWidth='md' fullWidth>
        <DialogTitle>{item.model}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* Display Small Image */}
            <Grid item xs={12} display='flex' justifyContent='center'>
              <CardMedia
                component='img'
                image={item.large_image_url}
                alt={item.description}
                sx={{ maxHeight: '300px', objectFit: 'contain' }}
              />
            </Grid>

            {/* Display Logo Image */}
            <Grid item xs={12} display='flex' justifyContent='center'>
              <CardMedia
                component='img'
                image={item.logo_image_url}
                alt='Brand Logo'
                sx={{ maxHeight: '100px', objectFit: 'contain' }}
              />
            </Grid>

            {/* Display Basic Info */}
            {Object.entries(item).map(([key, value], index) => {
              if (
                [
                  'brand_id',
                  'ca_approved',
                  'thumb_image_url',
                  'large_image_url',
                  'logo_image_url',
                  'facet_count',
                  'small_image_url',
                  'highres_image_url',
                  'brand_logo_url',
                  'facets',
                  'description', // Skip these to add them at the bottom
                  'paragraph_text',
                ].includes(key)
              )
                return null;

              // Only show if value is not null or empty string
              if (value && value !== '') {
                return (
                  <Grid item xs={6} key={index}>
                    <Typography variant='body2' color='textSecondary'>
                      <strong>{formatKey(key)}:</strong>
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      {typeof value === 'object' ? JSON.stringify(value) : value}
                    </Typography>
                    {index < Object.entries(item).length - 1 && <Divider />}
                  </Grid>
                );
              }
              return null;
            })}

            {/* Display Facets */}
            {item.facets && (
              <>
                {Object.entries(item.facets).map(
                  ([facetKey, facetValue], index) =>
                    facetValue &&
                    facetValue !== '' && ( // Only show if facetValue is not null or empty
                      <Grid item xs={6} key={index}>
                        <Typography variant='body2' color='textSecondary'>
                          <strong>{formatKey(facetKey)}:</strong>
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          {facetValue}
                        </Typography>
                        {index < Object.entries(item.facets).length - 1 && <Divider />}
                      </Grid>
                    )
                )}
              </>
            )}

            {/* Display Description and Paragraph Text at the Bottom */}
            {item.description && (
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Description
                </Typography>
                <Typography variant='body1' gutterBottom>
                  {item.description}
                </Typography>
              </Grid>
            )}

            {item.paragraph_text && (
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Additional Information
                </Typography>
                <Typography variant='body1' gutterBottom>
                  {item.paragraph_text}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            sx={{
              backgroundColor: color, // Color for close button
              color: theme.palette.getContrastText(color),
              '&:hover': {
                backgroundColor: color, // Remove hover effect
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ItemCard;
