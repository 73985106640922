import { Box, Typography, Grid, createTheme } from '@mui/material';

const TitleBar = (props) => {
  const { color, logo, title } = props;

  const theme = createTheme({
    palette: {
      primary: {
        main: color,
      },
    },
  });

  return (
    <Grid
      container
      alignItems='center'
      sx={{
        bgcolor: color,
        width: '100%',
        height: '75px',
      }}
    >
      <Grid
        item
        xs={3}
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ height: '100%', p: 1 }}
      >
        <Box
          component='img'
          src={logo}
          alt='Logo'
          sx={{
            height: '80%',
            width: 'auto',
            objectFit: 'contain',
          }}
        />
      </Grid>
      <Grid item xs={9} display='flex' alignItems='center' justifyContent='center' height='100%'>
        <Typography
          sx={{
            color: theme.palette.getContrastText(color),
            paddingLeft: 1,
          }}
          variant='h5'
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TitleBar;
